<template>
  <div>
    <ProductGrid
      v-on:onClick="validate"
      v-on:onOk="setValid"
      v-on:onNok="validate"
    ></ProductGrid>
    <div>
      <v-btn v-on:click="addItem">Adicionar +</v-btn>
    </div>
    <div>
      <v-dialog v-on:updateStatus="updateStatus" v-model="showValidationModal">
        <div class="modal">
          <h3 slot="header">Validar</h3>
          <div slot="body">
            <VerificationSteps
              v-bind:product="products[currentFocusIndex]"
              v-on:statusUpdate="updateStatus"
              v-on:deleteItem="deleteItem"
              v-on:close="showValidationModal = false"
              v-bind:resetModal="resetModal"
            ></VerificationSteps>
          </div>
          <v-btn
            @click="
              showValidationModal = false;
              resetModal++;
            "
            >Cancelar</v-btn
          >
        </div>
      </v-dialog>
      <v-dialog v-model="showProductAddModal">
        <div class="modal">
          <h3 slot="header">Adicionar Sem Nota</h3>
          <div slot="body">
            <AddNewItem
              v-bind:product="products[currentFocusIndex]"
              v-on:addItem="addNewItem"
            ></AddNewItem>
          </div>
          <v-btn @click="showProductAddModal = false">Cancelar</v-btn>
        </div>
      </v-dialog>
    </div>
  </div>
</template>

<style>
.modal {
  background-color: white;
  padding: 10px;
}
</style>

<script>
import ProductGrid from "./ProductGrid.vue";
import VerificationSteps from "./VerificationSteps.vue";
import AddNewItem from "./AddNewItem.vue";
import { mapState } from "vuex";

export default {
  name: "ValidationModal",
  components: {
    ProductGrid,
    VerificationSteps,
    AddNewItem,
  },
  data: function() {
    return {
      resetModal: 0,
      showValidationModal: false,
      showProductAddModal: false,
      currentFocusIndex: 0,
    };
  },
  computed: mapState(["products"]),
  methods: {
    addItem() {
      //this.showProductAddModal = true;
      this.showProductAddModal = true;
    },
    addNewItem(item) {
      //this.showProductAddModal = false;
      this.showProductAddModal = false;
      this.$store.state.products.push(item);
    },
    validate(index) {
      this.currentFocusIndex = index;
      this.showValidationModal = true;
    },
    setValid(index) {
      this.$store.state.products[index].status = "1";
    },
    updateStatus(item) {
      let matchedItem = {};
      this.showValidationModal = false;
      switch (item.status) {
        case "2":
          this.$store.state.products[this.currentFocusIndex].statusName =
            "Material já tinha vindo físico, agora veio a nota";
          this.$store.state.products[this.currentFocusIndex].match = item;
          break;
        case "3":
          this.$store.state.products[this.currentFocusIndex].statusName =
            "Material veio na nota primeiro, depois vai vir o físico";
          this.$store.state.products[this.currentFocusIndex].match = item;
          break;
        case "5":
          Object.assign(matchedItem, item);
          matchedItem.product.qty = Math.abs(
            parseInt(matchedItem.remainingItems)
          );
          matchedItem.product.status = "1";
          console.log(item);
          this.$store.state.products.push(matchedItem.product);
          item.status = "2";
          item.remainingItems = 0;
          item.comment =
            "Veio tanto material fisico, como itens a mais na nota para compensar saldo que estava sem nota";
          this.$store.state.products[this.currentFocusIndex].match = item;
      }
      this.$store.state.products[this.currentFocusIndex].status = item.status;
    },
    deleteItem() {
      this.$store.state.products.splice(this.currentFocusIndex, 1);
      this.showValidationModal = false;
    },
  },
};
</script>
