<script>
export default {
    name: "ProductCard",
    props: ["product"],
    methods: {
        clicked: function() {
            this.$emit("productClicked");
        },
    },
};
</script>

<template>
    <div v-bind:class="[
                'product-card',
                product.status == '1' ? 'status-ok' : '',
                product.status == '2' ? 'status-match-invoice' : '',
                product.status == '3' ? 'status-advance-invoice' : '',
                product.status == '4' ? 'status-match-physical' : '',
                product.status == '5' ? 'status-advance-physical' : '',
            ]">
        <div
            @click="clicked"
        >
            <img v-bind:src="product.image" />
            <div class="prod-cod">{{ product.cod }}</div>
            <div class="prod-name">{{ product.name }}</div>
            <div v-show="product.qty" class="prod-qty">{{ product.qty }} peças</div>
        </div>
        <div><slot /></div>
    </div>
</template>

<style scoped>
.product-card {
    padding: 5px;
    max-width: 240px;
    min-width: 160px;
    min-height: 250px;
    border: 1px solid black;
    border-radius: 5px;
}

.product-card img {
    width: 100%;
}

.prod-cod {
    font-weight: 600;
}

.prod-qty {
    font-weight: 700;
}

.status-ok {
    background-color: #a1fd84;
}

.status-match-physical {
    background-color: #7f8f3a;
}

.status-match-invoice {
    background-color: #9fb14e;
}

.status-advance-invoice {
    background-color: #a363df;
}

.status-advance-physical {
    background-color: #8252af;
}
</style>
