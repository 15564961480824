<script>
import api from "./api.js";
import ProductGridCardMatchList from "./ProductGridCardMatchList.vue";
import ProductCardHorizontal from "./ProductCardHorizontal.vue";
import ProductProfile from "./ProductProfile.vue";

export default {
  name: "VerificationSteps",
  props: ["product", "resetModal"],
  components: {
    ProductGridCardMatchList,
    ProductCardHorizontal,
    ProductProfile,
  },
  data: function() {
    return {
      step: "init",
      productsWaitingMatch: [],
      matchListSelectedIndex: 0,
      selectedProduct: {},
      isLoading: true,
      remainingItems: 0,
      comments: "",
    };
  },
  watch: {
    remainingItems: function(newValue) {
      if (newValue > 0) {
        this.message =
          "Ainda constará " + newValue + " peças aguardando vir nota";
      } else if (newValue < 0) {
        this.message =
          "Vai gerar um saldo de " +
          newValue * -1 +
          " peças com nota, mas sem físico";
      } else if (newValue == 0) {
        this.message = "";
      }
    },
    resetModal: function() {
      this.step = "init";
    },
  },
  methods: {
    productSave() {
      this.$emit("close");
    },
    firstStepClick(e) {
      switch (e) {
        case "ok":
          this.step = "material_is_in_load";
          this.fetchWaitingMatchList();
          break;
        case "nok":
          this.step = "material_is_not_in_load";
          this.fetchWaitingMatchList();
          break;
        case "delete":
          this.$emit("deleteItem");
          break;
        case "edit":
          this.step = "edit_product";
          break;
      }
    },
    editProductClick(e) {
      switch (e) {
        case "cancel":
          this.step = "init";
          break;
      }
    },
    finalizeOk(e) {
      switch (e) {
        case "ok":
          this.$emit("statusUpdate", { status: "1" });
          break;
        case "cancel":
          this.step = "init";
          break;
      }
    },
    secondStepClick(e) {
      switch (e) {
        case "cancel":
          this.step = "init";
          break;
        case "add":
          // Material fisico vai vir depois
          this.$emit("statusUpdate", { status: "3" });
          break;
      }
    },
    matchListClick(e) {
      switch (e) {
        case "cancel":
          this.selectedProduct = {};
          this.step = "material_is_not_in_load";
          break;
        case "all_in":
          // Abater item elas por elas (não gera saldo remanescente)
          this.$emit("statusUpdate", {
            status: "2",
            remainingItems: 0,
            comments: this.comments,
            product: this.selectedProduct,
          });
          break;
        case "fair_calc":
          if (this.remainingItems == "") {
            this.remainingItems = 0;
          }
          this.$emit("statusUpdate", {
            status: "2",
            remainingItems: this.remainingItems,
            comments: this.comments,
            product: this.selectedProduct,
          });
          break;
        case "partial_match":
          this.$emit("statusUpdate", {
            status: "5",
            remainingItems: this.remainingItems,
            comments: this.comments,
            product: this.selectedProduct,
          });
      }
    },
    fetchWaitingMatchList: async function() {
      this.productsWaitingMatch = await api.fetchProductsWithoutInvoice();
      this.isLoading = false;
    },
    startMatch: function(index) {
      this.step = "match_list";
      this.selectedProduct = this.productsWaitingMatch[index];
      this.remainingItems = this.selectedProduct.qty - this.product.qty;
      this.matchListSelectedIndex = index;
    },
    itemUpdated(imageUrl) {
      let product = this.product;
      product.image = imageUrl;
      this.$emit("productUpdated", product);
    },
  },
};
</script>
<template>
  <div>
    <template v-if="step == 'init'">
      <v-btn
        class="ma-2 btn"
        color="primary"
        elevation="2"
        outlined
        x-large
        v-on:click="firstStepClick('nok')"
        >Ver opções</v-btn
      >
      <v-btn
        class="ma-2 btn"
        color="red"
        elevation="2"
        outlined
        x-large
        v-on:click="firstStepClick('delete')"
        >Excluir item</v-btn
      >
      <v-btn
        class="ma-2 btn"
        color="black"
        elevation="2"
        outlined
        x-large
        v-on:click="firstStepClick('edit')"
        >Abrir Cadastro</v-btn
      >
    </template>
    <template v-else-if="step == 'edit_product'">
      <div>
        <ProductProfile
          v-on:saveDone="productSave"
          v-on:update="itemUpdated"
          v-bind:image_name="product.cod"
          v-bind:product="product"
        ></ProductProfile>
      </div>
    </template>
    <template v-else-if="step == 'material_is_not_in_load'">
      <h4>Abater alguma pendência?</h4>
      <div class="choice">
        <label>Caso sim:</label>
        <div>
          <p>Peças que ainda não vieram nota:</p>
          <v-progress-circular
            indeterminate
            v-if="isLoading"
          ></v-progress-circular>
          <ProductGridCardMatchList
            v-else
            v-bind:products="productsWaitingMatch"
            v-on:onClick="startMatch"
          ></ProductGridCardMatchList>
        </div>
      </div>
      <div class="choice">
        <label>Caso não:</label>
        <p>As peças vão vir depois</p>
        <v-btn
          class="ma-2"
          color="orange"
          elevation="2"
          outlined
          large
          v-on:click="secondStepClick('add')"
          v-bind:disabled="isLoading"
          >Adicionar na espera
        </v-btn>
      </div>
      <v-btn
        class="ma-2 btn"
        color="black"
        elevation="2"
        outlined
        x-large
        @click="secondStepClick('cancel')"
        >Voltar</v-btn
      >
    </template>
    <template v-else-if="step == 'match_list'">
      <h5>Peças nesta nota:</h5>
      <ProductCardHorizontal v-bind:product="product"></ProductCardHorizontal>
      <h5>Peças aguardando nota:</h5>
      <ProductCardHorizontal
        v-bind:product="selectedProduct"
      ></ProductCardHorizontal>
      <h5>Saldo depois:</h5>
      <input class="text-red" type="number" v-model="remainingItems" />
      <p class="warning-message" v-show="message !== ''">*{{ message }}</p>
      <input
        type="text"
        v-model="comments"
        placeholder="Adicionar um comentário"
      />
      <v-btn
        v-show="message !== ''"
        class="ma-2 btn"
        color="orange"
        elevation="2"
        outlined
        x-large
        fullwidth
        v-on:click="matchListClick('partial_match')"
        >Veio ainda {{ Math.abs(remainingItems) }} peças</v-btn
      >
      <v-btn
        v-show="message !== ''"
        class="ma-2 btn"
        color="red"
        elevation="2"
        outlined
        x-large
        v-on:click="matchListClick('fair_calc')"
        >Deixar saldo de {{ Math.abs(remainingItems) }}*</v-btn
      >
      <v-btn
        class="ma-2 btn"
        color="primary"
        elevation="2"
        outlined
        x-large
        v-on:click="matchListClick('all_in')"
        >Deixar saldo zero</v-btn
      >
      <v-btn
        class="ma-2 btn"
        color="black"
        elevation="2"
        outlined
        x-large
        v-on:click="matchListClick('cancel')"
        >Voltar</v-btn
      >
    </template>
  </div>
</template>

<style>
.warning-message {
  font-size: 10px;
  color: rgb(194, 23, 23);
}

.btn {
  width: 100%;
  height: auto;
}

.choice {
  background: white;
  border-radius: 4px;
  padding: 2px;
  border: 1px solid black;
  margin: 10px 0px 0px 0px;
}

.choice label {
  position: relative;
  top: -15px;
  background: white;
  padding: 0 5px;
}

.text-red {
  color: rgb(194, 23, 23);
}
</style>
