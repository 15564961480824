<template>
    <div>
        <h1>Verificando seu Login...</h1>
        <p class="warning">{{ message }}</p>
    </div>
</template>
<script>
import firebase from "firebase";
import userGuard from "../helpers/user-guard.js";

export default {
    data: function() {
        return {
            message: "",
        };
    },
    created() {
        if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
            // Additional state parameters can also be passed via URL.
            // This can be used to continue the user's intended action before triggering
            // the sign-in operation.
            // Get the email if available. This should be available if the user completes
            // the flow on the same device where they started it.
            var email = window.localStorage.getItem("emailForSignIn");
            if (!email) {
                // User opened the link on a different device. To prevent session fixation
                // attacks, ask the user to provide the associated email again. For example:
                email = window.prompt(
                    "Digite novamente o seu email para confirmar"
                );
            }
            // The client SDK will parse the code from the link for you.
            let t = this;
            firebase
                .auth()
                .signInWithEmailLink(email, window.location.href)
                .then(function(result) {
                    console.log(result);
                    // Clear email from storage.
                    window.localStorage.removeItem("emailForSignIn");
                    // You can access the new user via result.user
                    // Additional user info profile not available via:
                    // result.additionalUserInfo.profile == null
                    // You can check if the user is new or existing:
                    // result.additionalUserInfo.isNewUser
                    if (userGuard(result.user.email)) {
                        window.localStorage.setItem("token", email);
                        window.localStorage.setItem("user", result.user.email);
                        window.location = "/";
                    } else {
                        setInterval(function() {
                            t.message = "Você não tem permissão de acesso."
                            window.location = "/login";
                        }, 2000);
                    }
                })
                .catch(function(error) {
                    console.log(error);
                    // Some error occurred, you can inspect the code: error.code
                    // Common errors could be invalid email and invalid or expired OTPs.
                });
        }
    },
};
</script>

<style scoped>
.warning {
    color: red;
    font-size: 18px;
}
</style>
