<template>
    <div>
        <h1>Validando Carga</h1>
        <p v-if="invoiceId !== ''">Nota {{ invoiceId }}</p>
        <p v-else>Material sem nota</p>
        <div>
            <label>Carregou</label>
            <input type="date" v-model="loadDate" />
        </div>
        <div>
            <label>Descarregou</label>
            <input type="date" v-model="unloadDate" />
        </div>
        <p v-if="isLoading">Buscando...</p>
        <ValidationModal v-else-if="!invoiceNotFound" />
        <p v-else>Nota não encontrada</p>
        <v-btn v-bind:color="'primary'" v-on:click="validate"
            >Finalizar Validação
        </v-btn>
        <v-dialog v-model="schedulerModal">
            <div class="modal-schedule">
                <h3>Deseja adicionar os itens na Programação?</h3>
                <p>Ao adicionar os itens na programação você terá que inserir datas de previsão. Ou você pode apenas validar a nota.</p>
                <v-btn class="buttons" @click="saveValidation(true)" :color="'primary'">Sim</v-btn>
                <v-btn class="buttons" @click="saveValidation(false)">Não, apenas validar nota.</v-btn>
            </div>
        </v-dialog>
    </div>
</template>

<script>
import api from "../components/api.js";
import ValidationModal from "../components/ValidationModal.vue";
import { mapState } from "vuex";

export default {
    components: {
        ValidationModal,
    },
    data: function() {
        return {
            isLoading: true,
            loadDate: "",
            unloadDate: "",
            invoice: {},
            invoiceNotFound: false,
            schedulerModal: false,
        };
    },
    computed: mapState(["products", "invoiceId"]),
    methods: {
        today() {
            return new Date().toJSON().slice(0, 10);
        },
        validate() {
            this.schedulerModal = true;
        },
        async saveValidation(schedule) {
            let body = {
                invoiceId: this.invoiceId,
                loadDate: this.loadDate,
                unloadDate: this.unloadDate,
                date: this.invoice.date,
                validationId: this.invoiceId + "_" + new Date().toJSON(),
                products: this.$store.state.products,
            };
            console.log(body);
            let ret = await api.validateLoad(body, schedule);
            console.log(ret);
            if (ret.ok == true) {
                this.$store.state.products = [];
                this.$store.state.invoiceId = null;
                window.location = "/";
            }
        },
    },
    created: async function() {
        this.loadDate = this.today();
        this.unloadDate = this.today();
        if (this.$route.params.id) {
            this.$store.state.invoiceId = this.$route.params.id;
            let invoiceData = await api.fetchInvoiceData(this.invoiceId);
            if (invoiceData !== null) {
                invoiceData.items.forEach(function(item) {
                    item.status = "0";
                });
                this.$store.state.products = invoiceData.items;
                this.loadDate = new Date(invoiceData.date)
                    .toJSON()
                    .slice(0, 10);
                this.invoice = invoiceData;
            } else {
                this.invoiceNotFound = true;
            }
            this.isLoading = false;
        } else {
            this.isLoading = false;
        }
    },
};
</script>

<style scoped>
    .modal-schedule {
        background-color: white;
        height: 100%;
        padding: 20px;
    }
    .buttons {
        margin: 10px;
    }
</style>
