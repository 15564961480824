<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Programação</h1>
      </v-col>
      <v-col>
        <v-btn block color="primary" @click="saveToDatabase"
          >Salvar programação</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="unscheduledItems.length > 0 || basket.length > 0">
        <v-row>
          <v-row>
            <v-col>
              <h5>Montar um cesto</h5>
              <v-btn @click="saveBasket">Gravar cesto</v-btn>
              <!-- <Basket v-model="schedule"></Basket> -->
              <div>Ocupação: {{ basketOccupation }} %</div>

              <draggable
                class="basket-group list-group"
                :list="basket"
                group="people"
              >
                <a
                  v-for="(item, index) in basket"
                  :key="index"
                  @click="basketItemClickHandler(index)"
                >
                  <ProductCardHorizontal v-bind:product="item">
                  </ProductCardHorizontal>
                </a>
              </draggable>
              <draggable
                v-if="overflowBasket.length > 0"
                class="basket-group list-group"
                :list="overflowBasket"
                group="people"
              >
                <h5>Transbordo</h5>
                <ProductCardHorizontal
                  v-for="(item, index) in overflowBasket"
                  :key="index"
                  v-bind:product="item"
                >
                </ProductCardHorizontal>
              </draggable>
            </v-col>
          </v-row>
        </v-row>
        <v-row>
          <v-col>
            <h5>Itens Sem programação</h5>
            <draggable
              class="list-group"
              :list="unscheduledItems"
              group="people"
              @start="dragging = true"
              @end="dragging = false"
            >
              <ProductCardHorizontal
                v-for="(item, index) in unscheduledItems"
                :key="index"
                v-bind:product="item"
              >
              </ProductCardHorizontal>
            </draggable>
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <h2>Cestos Espera - {{ estimateLoad(queueBaskets) }} minutos</h2>
        <div class="baskets-grid">
          <draggable
            :scroll-sensitivity="200"
            :force-fallback="true"
            class="list-group"
            :list="queueBaskets"
            group="basket"
          >
            <draggable
              v-for="(basket, index) in queueBaskets"
              :key="index"
              class="basket elevation-4"
              :list="basket.items"
              group="people"
              draggable=".basket-item"
            >
              <v-row>
                <v-col class="col-6">
                  <v-text-field
                    v-model="basket.estimatedDoneDate"
                    label="Data Finalização"
                    persistent-hint
                    :hint="dateHint(basket.estimatedDoneDate)"
                    v-on:click="openDateModal(index, basket)"
                  ></v-text-field>
                </v-col>
                <v-col class="col-6">
                  <span>Estimativa (min):</span
                  ><input
                    class="small-input"
                    v-model="basket.estimatedExecutionTime"
                  />
                </v-col>
              </v-row>
              <ProductCardHorizontal
                v-for="(item, index) in basket.items"
                :key="index"
                v-bind:product="item"
                class="basket-item"
              >
              </ProductCardHorizontal>
              <div slot="footer">
                <v-btn
                  text
                  color="error"
                  block
                  @click="undoBasket(index, queueBaskets)"
                  >Desfazer cesto</v-btn
                >
                <v-btn
                  block
                  color="success"
                  @click="markBasketAsDone(index, queueBaskets)"
                  >Concluído</v-btn
                >
              </div>
            </draggable>
          </draggable>
        </div>
      </v-col>
      <v-col>
        <h2>Paraíba - {{ estimateLoad(smallRackBaskets) }} minutos</h2>
        <div class="baskets-grid">
          <draggable
            :scroll-sensitivity="200"
            :force-fallback="true"
            class="list-group"
            :list="smallRackBaskets"
            group="basket"
          >
            <draggable
              v-for="(basket, index) in smallRackBaskets"
              :key="index"
              class="basket elevation-4"
              :list="basket.items"
              group="people"
              draggable=".basket-item"
            >
              <v-row>
                <v-col class="col-6">
                  <v-text-field
                    v-model="basket.estimatedDoneDate"
                    label="Data Finalização"
                    persistent-hint
                    :hint="dateHint(basket.estimatedDoneDate)"
                    v-on:click="openDateModal(index, basket)"
                  ></v-text-field>
                </v-col>
                <v-col class="col-6">
                  <span>Estimativa (min):</span
                  ><input
                    class="small-input"
                    v-model="basket.estimatedExecutionTime"
                  />
                </v-col>
              </v-row>
              <ProductCardHorizontal
                v-for="(item, index) in basket.items"
                :key="index"
                v-bind:product="item"
                class="basket-item"
              >
              </ProductCardHorizontal>
              <div slot="footer">
                <v-btn
                  text
                  color="error"
                  block
                  @click="undoBasket(index, smallRackBaskets)"
                  >Desfazer cesto</v-btn
                >
                <v-btn
                  block
                  color="success"
                  @click="markBasketAsDone(index, smallRackBaskets)"
                  >Concluído</v-btn
                >
              </div>
            </draggable>
          </draggable>
        </div>
      </v-col>
      <v-col>
        <h2>Neto - {{ estimateLoad(bigRackBaskets) }} minutos</h2>
        <div class="baskets-grid">
          <draggable
            :scroll-sensitivity="200"
            :force-fallback="true"
            class="list-group"
            :list="bigRackBaskets"
            group="basket"
          >
            <draggable
              v-for="(basket, index) in bigRackBaskets"
              :key="index"
              class="basket elevation-4"
              :list="basket.items"
              group="people"
              draggable=".basket-item"
            >
              <v-row>
                <v-col class="col-6">
                  <v-text-field
                    v-model="basket.estimatedDoneDate"
                    label="Data Finalização"
                    persistent-hint
                    :hint="dateHint(basket.estimatedDoneDate)"
                    v-on:click="openDateModal(index, basket)"
                  ></v-text-field>
                </v-col>
                <v-col class="col-6">
                  <span>Estimativa (min):</span
                  ><input
                    class="small-input"
                    v-model="basket.estimatedExecutionTime"
                  />
                </v-col>
              </v-row>
              <ProductCardHorizontal
                v-for="(item, index) in basket.items"
                :key="index"
                v-bind:product="item"
                class="basket-item"
              >
              </ProductCardHorizontal>
              <div slot="footer">
                <v-btn
                  text
                  color="error"
                  block
                  @click="undoBasket(index, bigRackBaskets)"
                  >Desfazer cesto</v-btn
                >
                <v-btn
                  block
                  color="success"
                  @click="markBasketAsDone(index, bigRackBaskets)"
                  >Concluído</v-btn
                >
              </div>
            </draggable>
          </draggable>
        </div>
      </v-col>
      <v-col>
        <h2>Teste</h2>
        <BasketList :baskets="smallRackBaskets"></BasketList>
      </v-col>
    </v-row>
    <v-dialog v-model="showBasketItemOptionsModal">
      <div class="modal">
        <h5>Opções</h5>
        <ProductCardHorizontal v-bind:product="selectedBasketItem">
        </ProductCardHorizontal>
        <div>
          <v-slider
            v-model="basketItemQty"
            :max="selectedBasketItem.qty"
            label="Quantidade"
            class="align-center"
          >
            <template v-slot:append>
              <v-text-field
                v-model="basketItemQty"
                class="mt-0 pt-0"
                type="number"
                style="width: 60px"
              ></v-text-field>
            </template>
          </v-slider>
        </div>
        <v-btn @click="basketItemsOptionsSplitItem">Aplicar</v-btn>
      </div>
    </v-dialog>
    <v-dialog v-model="showBasketDoneOptionsModal">
      <div class="modal">
        <v-container>
          <v-row>
            <h2>Cesto Finalizado</h2>
          </v-row>
          <v-row>
            <span>Tempo de Execução: </span
            ><input v-model="realExecutionTime" />
          </v-row>
          <v-row>
            <v-col>
              <v-btn @click="basketDoneApply">Marcar Concluído</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-dialog>
    <v-menu
      v-model="dateMenu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="auto"
    >
      <v-date-picker
        v-model="selectedBasketEstimatedDoneDate"
        no-title
        @input="saveEstimatedDoneDate(value)"
      ></v-date-picker>
    </v-menu>
  </v-container>
</template>

<script>
import api from "../components/api.js";
import ProductCardHorizontal from "../components/ProductCardHorizontal.vue";
import draggable from "vuedraggable";
import BasketList from "../components/BasketList.vue";

let rules = {
  59009082200: {
    name: "DIVISORIA",
    basketType: "basket",
    maxPerBasket: 280,
  },
  59009125900: {
    name: "CABELO DE NEGO",
    basketType: "basket",
    maxPerBasket: 200,
  },
  59009137500: {
    name: "BANDEJA GRANDE",
    basketType: "basket",
    maxPerBasket: 225,
  },
  59009137600: {
    name: "BANDEJA PEQUENA",
    basketType: "basket",
    maxPerBasket: 300,
  },
};

export default {
  components: {
    ProductCardHorizontal,
    draggable,
    BasketList,
  },
  data: function() {
    return {
      value: 1,
      scheduleItems: [],
      showDialog: true,
      basket: [],
      basketOccupation: 0,
      overflowBasket: [],
      queueBaskets: [],
      bigRackBaskets: [],
      smallRackBaskets: [],
      unscheduledItems: [],
      showBasketItemOptionsModal: false,
      selectedBasketItem: {},
      selectedBasketItemIndex: 0,
      selectedBasket: null,
      selectedBasketIndex: null,
      selectedBasketEstimatedDoneDate: null,
      realExecutionTime: 0,
      showBasketDoneOptionsModal: false,
      dateMenu: false,
      basketItemQty: 0,
      doneItems: [],
    };
  },
  created: async function() {
    let scheduleItems = await api.fetchScheduleItems();
    if (scheduleItems.unscheduledItems) {
      this.unscheduledItems = this.convertToArray(
        scheduleItems.unscheduledItems
      );
    }
    if (scheduleItems.basket.items) {
      this.basket = this.convertToArray(scheduleItems.basket.items);
    }
    if (scheduleItems.basket.overflowBasket) {
      this.overflowBasket = this.convertToArray(
        scheduleItems.basket.overflowBasket
      );
    }
    if (scheduleItems.basket.basketOccupation) {
      this.basketOccupation = scheduleItems.basket.basketOccupation;
    }
    if (scheduleItems.queueBaskets) {
      this.queueBaskets = this.convertToArray(scheduleItems.queueBaskets);
    }
    if (scheduleItems.smallRackBaskets) {
      this.smallRackBaskets = this.convertToArray(
        scheduleItems.smallRackBaskets
      );
    }
    if (scheduleItems.bigRackBaskets) {
      this.bigRackBaskets = this.convertToArray(scheduleItems.bigRackBaskets);
    }
  },
  methods: {
    dateHint(date) {
      let today = new Date(new Date().toDateString());
      let targetDate = new Date(date + "T00:00");
      let days = parseInt((targetDate - today) / (24 * 60 * 60 * 1000));
      let message = null;
      switch (days) {
        case 0:
          message = "Hoje";
          break;
        case 1:
          message = "Amanhã";
          break;
        case 2:
          message = "Depois de amanhã";
          break;
        case -1:
          message = "Ontem";
          break;
        default:
          if (isNaN(days)) {
            message = null;
          } else if (days > 0) {
            message = "Daqui " + days + " dias";
          } else {
            message = Math.abs(days) + " dias atrás";
          }
          break;
      }
      return message;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    basketItemClickHandler: function(index) {
      this.selectedBasketItem = this.basket[index];
      this.selectedBasketItemIndex = index;
      this.basketItemQty = this.selectedBasketItem.qty;
      this.showBasketItemOptionsModal = true;
    },
    basketItemsOptionsSplitItem: function() {
      if (this.selectedBasketItem.qty !== this.basketItemQty) {
        let originalQty = this.selectedBasketItem.qty;
        let newQty = this.basketItemQty;
        let overflowItem = {};
        Object.assign(overflowItem, this.basket[this.selectedBasketItemIndex]);
        overflowItem.qty = originalQty - newQty;
        this.basket[this.selectedBasketItemIndex].qty = newQty;
        this.overflowBasket.push(overflowItem);
      }
      this.selectedBasketItem = {};
      this.selectedBasketItemIndex = 0;
      this.basketItemQty = 0;
      this.showBasketItemOptionsModal = false;
    },
    undoBasket: function(index, basket) {
      let t = this;
      basket[index].items.forEach(function(item) {
        t.unscheduledItems.push(item);
      });
      basket.splice(index, 1);
    },
    openDateModal: function(index, basket) {
      this.selectedBasket = basket;
      this.selectedBasketIndex = index;
      this.dateMenu = true;
      this.selectedBasketEstimatedDoneDate = basket.estimatedDoneDate;
    },
    saveEstimatedDoneDate: function() {
      this.selectedBasket.estimatedDoneDate = this.selectedBasketEstimatedDoneDate;
      this.dateMenu = false;
      this.showBasketDoneOptionsModal = false;
      this.selectedBasket = null;
      this.selectedBasketIndex = null;
      this.selectedBasketEstimatedDoneDate = null;
    },
    markBasketAsDone: function(index, basket) {
      this.selectedBasket = basket;
      this.selectedBasketIndex = index;
      this.showBasketDoneOptionsModal = true;
    },
    basketDoneApply: async function() {
      let doneBasket = {
        realDoneDate: new Date().toISOString().slice(0, 10),
        estimatedExecutionTime: this.selectedBasket[this.selectedBasketIndex]
          .estimatedExecutionTime,
        estimatedDoneDate: this.selectedBasket[this.selectedBasketIndex]
          .estimatedDoneDate,
        realExecutionTime: this.realExecutionTime,
        items: this.selectedBasket[this.selectedBasketIndex].items,
      };
      await api.saveDoneBasket(doneBasket);
      this.realExecutionTime = 0;
      this.selectedBasket[this.selectedBasketIndex].items.forEach(function(
        item
      ) {
        this.doneItems.push(item);
      });
      this.selectedBasket.splice(this.selectedBasketIndex, 1);
      this.showBasketDoneOptionsModal = false;
      this.selectedBasket = null;
      this.selectedBasketIndex = null;
      this.saveToDatabase();
    },
    saveToDatabase: async function() {
      let schedule = {
        queueBaskets: this.queueBaskets,
        smallRackBaskets: this.smallRackBaskets,
        bigRackBaskets: this.bigRackBaskets,
        basket: {
          items: this.basket,
          basketOccupation: this.basketOccupation,
          overflowBasket: this.overflowBasket,
        },
        unscheduledItems: this.unscheduledItems,
      };
      let ret = await api.saveSchedule(schedule);
      return ret;
    },
    saveBasket: function() {
      if (this.basket.length > 0) {
        this.queueBaskets.push({
          estimatedExecutionTime: 0,
          estimatedDoneDate: null,
          items: this.basket,
        });
        this.basket = this.overflowBasket;
        this.overflowBasket = [];
      }
    },
    convertToArray: function(obj) {
      let array = [];
      Object.keys(obj).forEach(function(key) {
        array.push(obj[key]);
      });
      return array;
    },
    add: function() {
      this.unscheduledItems.push({ key: "hh", items: [] });
    },
    joinSimilarItemsSameInvoice: function(val) {
      let itemCodInvoice = {};
      let changed = false;
      if (val.length > 0) {
        val.forEach(function(item) {
          let pointer = item.cod + "_" + item.invoiceId;
          if (itemCodInvoice[pointer]) {
            let current = {};
            Object.assign(current, itemCodInvoice[pointer]);
            current.qty += parseInt(item.qty);
            Object.assign(itemCodInvoice[pointer], current);
            changed = true;
          } else {
            itemCodInvoice[pointer] = item;
          }
        });
        if (changed) {
          let unduplicatedItems = [];
          Object.keys(itemCodInvoice).forEach(function(key) {
            unduplicatedItems.push(itemCodInvoice[key]);
          });
          return unduplicatedItems;
        }
      }
      return val;
    },
    estimateLoad: function(baskets) {
      let load = 0;
      baskets.forEach(function(basket) {
        load += parseInt(basket.estimatedExecutionTime);
      });
      return load;
    },
  },
  watch: {
    basket: function(val) {
      let t = this;
      let ocuppation = 0;
      val.forEach(function(item) {
        let rule = rules[item.cod];
        if (rule) {
          // basket isnt full yet
          let emptySpace = (100 - ocuppation) / 100;
          let availableSlots = parseInt(emptySpace * rule.maxPerBasket);
          if (item.qty <= availableSlots) {
            // it will fit
            ocuppation += parseInt((1 / rule.maxPerBasket) * item.qty * 100);
          } else {
            // it wont fit
            let overflowItem = {};
            Object.assign(overflowItem, item);
            overflowItem.qty = item.qty - availableSlots;
            item.qty = availableSlots;
            t.overflowBasket.push(overflowItem);
            ocuppation = 100;
          }
        }
      });
      this.basketOccupation = ocuppation;
    },
    unscheduledItems: function(val) {
      this.unscheduledItems = this.joinSimilarItemsSameInvoice(val);
    },
    smallRackBaskets: function(val) {
      console.log(val);
    },
  },
};
</script>

<style scoped>
.products-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.small-input {
  padding: 5px;
  width: 60px;
  background: #e6fff9;
}

.dragArea {
  min-height: 50px;
  outline: 1px dashed;
}
.basket-group {
  background-color: rgb(211, 211, 240);
  border: 2px solid black;
  margin: 5px;
  min-height: 20px;
}

.basket {
  position: relative;
  margin-bottom: 10px;
  padding: 20px 20px 20px 20px;
  background-color: #f3f3f3;
  border-radius: 5%;
}

.list-group {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 20px;
}

.basket-item {
  background-color: white;
  padding: 5px;
}

.modal {
  background-color: white;
  padding: 10px;
}
</style>
