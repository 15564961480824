<script>
import server from "../firebase/firebase.js";
import ProductCard from "./ProductCard.vue";
import { mapState } from "vuex";

export default {
    components: {
        ProductCard,
    },
    data: function() {
        return {};
    },
    computed: mapState(["products"]),
    created: function() {
        let t = this;
        this.products.forEach(async function(product, index) {
            let productData = await server.getProduct(product.cod);
            if (productData !== null) {
                t.$store.state.products[index].image = productData.image;
            } else {
                let newProduct = {
                    cod: product.cod,
                    name: product.name,
                    billingCod: "",
                    nickname: "",
                    weight: 0,
                    image:
                        "https://firebasestorage.googleapis.com/v0/b/ariam-galvanorte.appspot.com/o/sem-foto.jpg?alt=media",
                };
                await server.saveProduct(newProduct);
                t.$store.state.products[index].image = newProduct.image;
            }
        });
    },
    methods: {
        handleClick: function(index) {
            this.$emit("onClick", index);
        },
        setOk: function(index) {
            this.$emit("onOk", index);
        },
        setNok: function(index) {
            this.$emit("onNok", index);
        },
    },
};
</script>
<template>
    <div class="product-grid">
        <div v-for="(product, index) in products" v-bind:key="index">
            <ProductCard
                v-bind:product="product"
                v-on:productClicked="handleClick(index)"
            >
                <v-btn class="mx-2" fab dark color="teal" @click="setOk(index)"
                    ><span dark x-large>OK</span></v-btn
                >
                <v-btn class="mx-2" fab dark color="red" @click="setNok(index)"
                    ><span x-large color="black">...</span></v-btn
                >
            </ProductCard>
        </div>
    </div>
</template>

<style>
.product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-row-gap: 10px;
    grid-column-gap: 5%;
}
</style>
