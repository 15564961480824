<script>
import api from "./api.js";
import ProductGridCardMatchList from "./ProductGridCardMatchList.vue";
import ProductCardHorizontal from "./ProductCardHorizontal.vue";
import SearchProduct from "./SearchProduct.vue";

export default {
    components: {
        ProductGridCardMatchList,
        ProductCardHorizontal,
        SearchProduct,
    },
    data: function() {
        return {
            step: "init",
            itemCod: "",
            itemName: "",
            itemQty: "",
            itemDetails: "",
            expression: "",
            resultado: "",
            productsWaitingMatch: [],
            showCalculator: false,
            isLoading: true,
            selectedProduct: {},
        };
    },
    async created() {
        this.productsWaitingMatch = await api.fetchProductsOnlyWithInvoice();
        this.isLoading = false;
    },
    watch: {
        expression: function(newValue) {
            this.resultado = eval(newValue);
        },
        itemQty: function(newValue) {
            this.selectedProduct.qty = newValue;
        },
        itemDetails: function(newValue) {
            this.selectedProduct.details = newValue;
        },
    },
    methods: {
        async firstStepClick(e) {
            switch (e) {
                case "add":
                    this.step = "validation";
                    break;
                case "cancel":
                    this.step = "init";
                    break;
            }
        },
        secondStepClick(e) {
            switch (e) {
                case "cancel":
                    this.step = "init";
                    break;
                case "start_match_list":
                    this.step = "match_list";
                    break;
                case "add_to_waiting_match_list":
                    // Material físico chegou, mas nota vai vir depois
                    this.$emit("addItem", {
                        status: "5",
                        cod: this.selectedProduct.cod,
                        name: this.selectedProduct.name,
                        image: this.selectedProduct.image,
                        qty: this.selectedProduct.qty,
                        obs: this.selectedProduct.details,
                    });
                    break;
            }
        },
        productClicked(index) {
            // Material ja tinha vindo na nota, agora veio o fisico
            let obj = Object.assign({}, this.productsWaitingMatch[index])
            obj.status = "4",
            console.log(obj);
            this.$emit("addItem", obj);
        },
        setProduct(product) {
            if(typeof product === 'object' && product !== null) {
                this.selectedProduct = Object.assign(product);
            }
            else {
                 this.selectedProduct = Object.assign({name: product, cod: product.split(' ')[0], image: "https://firebasestorage.googleapis.com/v0/b/ariam-galvanorte.appspot.com/o/sem-foto.jpg?alt=media&token=2685bfe3-0a82-4fc5-b614-48238c58a107", nickname: "", weight: 0});
            }  
        },
    },
};
</script>
<template>
    <div>
        <template v-if="step == 'init'">
            <h5>Já tinha vindo nota antes?</h5>
            <div class="choice">
                <label>Caso sim:</label>
                <p v-if="isLoading">Buscando... Aguarde</p>
                <ProductGridCardMatchList
                    v-else
                    v-bind:products="productsWaitingMatch"
                    v-on:onClick="productClicked"
                ></ProductGridCardMatchList>
            </div>
            <div class="choice">
                <label>Caso Não:</label>
                <SearchProduct v-on:selected="setProduct"></SearchProduct>
                <ProductCardHorizontal
                    v-bind:product="selectedProduct"
                ></ProductCardHorizontal>
                <div>
                    <input
                        style="width: auto;"
                        type="number"
                        v-model="itemQty"
                        placeholder="Quantidade"
                    />
                    <v-btn v-on:click="showCalculator = showCalculator != true"
                        ><span>CALC</span></v-btn
                    >
                </div>
                <div v-show="showCalculator" style="display:flex;">
                    <label>Calculadora: </label>
                    <input
                        style="width:100px;"
                        type="text"
                        v-model="expression"
                    />
                    <p>=</p>
                    <input
                        style="width:40px;"
                        type="text"
                        v-model="resultado"
                    />
                </div>
                <div>
                    <input placeholder="Observações" v-model="itemDetails" />
                </div>
            </div>
            <div>
                <v-btn
                    class="ma-2"
                    color="primary"
                    elevation="2"
                    outlined
                    large
                    v-on:click="firstStepClick('add')"
                    >Adicionar</v-btn
                >
            </div>
        </template>
        <template v-else-if="step == 'validation'">
            <div>
                <h5>Você está adicionando:</h5>
                <ProductCardHorizontal
                    v-bind:product="selectedProduct"
                ></ProductCardHorizontal>
            </div>
            <div>
                <v-btn
                    class="ma-2"
                    color="primary"
                    elevation="2"
                    outlined
                    large
                    v-on:click="secondStepClick('add_to_waiting_match_list')"
                    >Nota vai vir depois</v-btn
                >
                <v-btn
                    class="ma-2"
                    color="black"
                    elevation="2"
                    outlined
                    large
                    v-on:click="secondStepClick('cancel')"
                    >Voltar</v-btn
                >
            </div>
        </template>
    </div>
</template>

<style scoped>
.choice {
    background: white;
    border-radius: 4px;
    padding: 2px;
    border: 1px solid black;
    margin: 10px 0px 0px 0px;
}

.choice label {
    position: relative;
    top: -15px;
    background: white;
    padding: 0 5px;
}
</style>
