<template>
    <div>
        <div class="head">
            <h1>Relatório</h1>
            <img height="60" src="https://firebasestorage.googleapis.com/v0/b/ariam-galvanorte.appspot.com/o/logo_small.png?alt=media&token=4a4164c7-b40b-4cc0-8557-30abf52672ca" />
        </div>
        <div v-show="loading" class="loading-icon">
            Carregando dados...
            <v-progress-circular indeterminate></v-progress-circular>
        </div>
        <div v-show="!loading">
            <h2>Itens aguardando Nota Fiscal</h2>
            <p>Itens que já vieram na Galvanorte, porém não vieram na nota, ou a quantidade real de peças era superior que o incluso na nota.</p>
            <div class="product-grid">
                <div v-for="(product, index) in pendingInvoices" v-bind:key="index">
                    <ProductCard
                        v-bind:product="product"
                        v-on:productClicked="handleClick(index)"
                    >
                    <p>Material veio junto com NF {{product.invoiceId}}</p>
                    </ProductCard>
                </div>
            </div>
                <div v-if="pendingInvoices.length < 1">Sem pendências</div>
            <h2>Itens aguardando Físico</h2>
            <p>Itens que vieram em alguma nota anterior mas ainda não chegaram as peças na Galvanorte, ou que a quantidade na nota era superior à quantidade real de peças.</p>
            <div class="product-grid">
                <div v-for="(product, index) in pendingProducts" v-bind:key="index">
                    <ProductCard
                        v-bind:product="product"
                        v-on:productClicked="handleClick(index)"
                    >
                    <p>Material veio incluso na nota {{product.invoiceId}}</p>
                    </ProductCard>
                </div>
            </div>
            <div v-if="pendingProducts.length < 1">Sem pendências</div>
        </div>
    </div>
</template>

<script>
import api from "./api.js"
import ProductCard from "./ProductCard.vue"
export default {
    components: {
        ProductCard
    },
    data: function () {
        return {
            pendingProducts: [],
            pendingInvoices: [],
            loading: true
        }
        
    },
    created: function () {
        let pendingItems = api.fetchPendingItems().then((items) => {
            console.log(items);
            this.pendingProducts = items.pendingProduct;
            this.pendingInvoices = items.pendingInvoice;
            this.loading = false;
        });
        console.log(pendingItems);
    }
}
</script>

<style scoped>

.head {
    display: grid;
    grid-template-columns: 3fr 1fr;
}

.product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-row-gap: 10px;
    grid-column-gap: 5%;
}
</style>
