<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Programação</h1>
      </v-col>
    </v-row>
    <v-row>
      <div v-for="(item, index) in scheduledItems" :key="index">
        <ProductCardHorizontal :product="item">
          <strong>Previsão {{ item.duedate }}</strong>
        </ProductCardHorizontal>
      </div>
      <div v-for="(item, index) in unscheduledItems" :key="index">
        <ProductCardHorizontal :product="item"></ProductCardHorizontal>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import api from "../components/api.js";
import ProductCardHorizontal from "../components/ProductCardHorizontal.vue";

export default {
  components: {
    ProductCardHorizontal,
  },
  data: function() {
    return {
      unscheduledItems: [],
      scheduledItems: [],
    };
  },
  created: async function() {
    let scheduleItems = await api.fetchScheduleItems();
    if (scheduleItems.unscheduledItems) {
      this.unscheduledItems = this.convertToArray(
        scheduleItems.unscheduledItems
      );
    }
    if (scheduleItems.basket.items) {
      this.unscheduledItems = this.unscheduledItems.concat(
        this.convertToArray(scheduleItems.basket.items)
      );
    }
    if (scheduleItems.basket.overflowBasket) {
      this.unscheduledItems = this.unscheduledItems.concat(
        this.convertToArray(scheduleItems.basket.overflowBasket)
      );
    }
    if (scheduleItems.queueBaskets) {
      this.unscheduledItems = this.unscheduledItems.concat(
        this.convertToArray(scheduleItems.queueBaskets)
      );
    }
    if (scheduleItems.smallRackBaskets) {
      this.scheduledItems = this.scheduledItems.concat(
        this.convertToSchedule(scheduleItems.smallRackBaskets)
      );
    }
    if (scheduleItems.bigRackBaskets) {
      this.scheduledItems = this.scheduledItems.concat(
        this.convertToSchedule(scheduleItems.bigRackBaskets)
      );
    }
    this.scheduledItems.sort(function(a, b) {
      return (
        parseInt(a.duedate.replaceAll("-", "")) -
        parseInt(b.duedate.replaceAll("-", ""))
      );
    });
  },
  methods: {
    convertToArray: function(obj) {
      let array = [];
      Object.keys(obj).forEach(function(key) {
        array.push(obj[key]);
      });
      return array;
    },
    convertToSchedule: function(baskets) {
      let sch = [];
      let itemDateObj = {};
      baskets.forEach(function(basket) {
        let duedate = basket.estimatedDoneDate;
        basket.items.forEach(function(item) {
          if (itemDateObj[duedate + "_" + item.cod] === undefined) {
            itemDateObj[duedate + "_" + item.cod] = {
              duedate: duedate,
              cod: item.cod,
              image: item.image,
              name: item.name,
              invoiceId: item.invoiceId,
              qty: parseInt(item.qty),
            };
          } else {
            itemDateObj[duedate + "_" + item.cod].qty += parseInt(item.qty);
          }
        });
      });
      Object.keys(itemDateObj).forEach(function(key) {
        sch.push(itemDateObj[key]);
      });
      return sch;
    },
  },
  watch: {},
};
</script>

<style scoped>
.products-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.small-input {
  padding: 5px;
  width: 60px;
  background: #e6fff9;
}

.dragArea {
  min-height: 50px;
  outline: 1px dashed;
}
.basket-group {
  background-color: rgb(211, 211, 240);
  border: 2px solid black;
  margin: 5px;
  min-height: 20px;
}

.basket {
  position: relative;
  margin-bottom: 10px;
  padding: 20px 20px 20px 20px;
  background-color: #f3f3f3;
  border-radius: 5%;
}

.list-group {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 20px;
}

.basket-item {
  background-color: white;
  padding: 5px;
}

.modal {
  background-color: white;
  padding: 10px;
}
</style>
