<template>
  <draggable
    :scroll-sensitivity="200"
    :force-fallback="true"
    class="list-group"
    :list="baskets"
    group="basket"
  >
    <Basket v-for="(basket, index) in baskets" :basket="basket" :key="index">
    </Basket>
  </draggable>
</template>
<script>
import draggable from "vuedraggable";
import Basket from "./Basket";

export default {
  name: "BasketList",
  props: ["baskets"],
  methods: {},
  components: {
    draggable,
    Basket,
  },
  created: function() {},
  computed: {},
};
</script>
<style scoped></style>
