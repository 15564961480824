<template>
	<div>
		<h1>Entregas</h1>
		<v-btn @click="deliveryCardsModal = true">Iniciar Nova Entrega</v-btn>
		<v-card class="review-delivery" v-if="itemsSelectionDone">
			<h2
				@click="
					selectionIndex = 0;
					deliveryCardsModal = true;
				"
			>
				Entrega em Andamento
			</h2>
			<v-btn color="primary" @click="confirmDeliveryModal = true">
				Confirmar entrega
			</v-btn>
			<div class="listing">
				<div
					@click="
						selectionIndex = index;
						deliveryCardsModal = true;
					"
					v-for="(item, index) in undeliveredItems"
					v-bind:key="index"
				>
					<div class="thumb" v-if="item.loadedQty > 0">
						<div><img :src="item.image" /></div>
						<div>
							<div>
								<strong>{{ item.cod }}</strong>
							</div>
							<div>{{ item.name }}</div>
						</div>
						<div>{{ item.loadedQty }} peças</div>
					</div>
				</div>
			</div>
		</v-card>
		<v-card>
			<v-card-title>Entregas Realizadas</v-card-title>
			<v-simple-table>
				<template v-slot:default>
					<thead>
						<tr>
							<th class="text-left">
								Data
							</th>
						</tr>
					</thead>

					<tbody>
						<tr v-for="(delivery, index) in deliveries" :key="index">
							<td>{{ new Date(delivery.date).toLocaleString() }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</v-card>

		<v-dialog
			fullscreen
			v-model="deliveryCardsModal"
			v-if="deliveryCardsModal != false"
		>
			<div class="modal-white">
				<div class="row">
					<div class="col">
						<h3>Nova Entrega</h3>
					</div>
					<div class="col">
						<a @click="finalCheck">Ok, revisar entrega</a>
					</div>
				</div>
				<div class="row controles">
					<div class="col">
						<v-btn :disabled="selectionIndex <= 0" @click="prev"
							>Anterior</v-btn
						>
					</div>
					<div class="col">
						<v-btn
							:disabled="selectionIndex >= undeliveredItems.length - 1"
							@click="next"
							>Próximo</v-btn
						>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<div class="row">
							<div class="col">
								<img :src="undeliveredItems[selectionIndex].image" />
							</div>
							<div class="col">
								<p>{{ undeliveredItems[selectionIndex].cod }}</p>
								<p>
									<strong>{{ undeliveredItems[selectionIndex].name }}</strong>
								</p>
								<p>
									Nota {{ undeliveredItems[selectionIndex].invoiceId }}
									{{ undeliveredItems[selectionIndex].originalQty }} pçs
								</p>
							</div>
						</div>

						<p>
							{{
								new Date(undeliveredItems[selectionIndex].date).toLocaleString()
							}}
						</p>
						<v-btn
							@click="qtyModalShow = true"
							:color="
								undeliveredItems[selectionIndex].loadedQty > 0 ? 'green' : ''
							"
							class="qty"
						>
							{{ undeliveredItems[selectionIndex].loadedQty || 0 }} pçs /
							{{ undeliveredItems[selectionIndex].qty }} pçs
							<v-dialog v-model="qtyModalShow">
								<div class="modal-qty">
									<h2>Quantidade</h2>
									<input
										v-model.number="undeliveredItems[selectionIndex].loadedQty"
										min="0"
										placeholder="Insira quantidade..."
										class="qty-input"
										type="number"
										:max="undeliveredItems[selectionIndex].qty"
									/>
									<div>
										Quantidade nesta nota:
										{{ undeliveredItems[selectionIndex].qty }}
									</div>
									<v-btn @click="qtyModalShow = false">Pronto</v-btn>
								</div>
							</v-dialog>
						</v-btn>
					</div>
					<div class="commands">
						<v-btn @click="goingClickHandler" class="buttons"
							>Está indo tudo</v-btn
						>
						<v-btn @click="notGoingClickHandler" class="buttons">Não vai</v-btn>
						<v-btn @click="alreadyDeliveredModal = true" class="buttons"
							>Já foi
							<v-dialog v-model="alreadyDeliveredModal">
								<div class="modal-already-delivered">
									<h2>Carga da Entrega</h2>
									<v-card
										v-for="(delivery, index) in deliveries"
										v-bind:key="index"
									>
										<div
											@click="
												undeliveredItems[selectionIndex].alreadyDelivered =
													delivery.date;
												alreadyDeliveredModal = false;
											"
										>
											{{ delivery.date }}
										</div>
									</v-card>
								</div>
							</v-dialog>
						</v-btn>
						<a class="cancel-link" @click="cancelDeliveryModal = true"
							>Cancelar esta entrega</a
						>
					</div>
				</div>
			</div>
		</v-dialog>
		<v-dialog v-model="cancelDeliveryModal">
			<div class="modal-white">
				<h3>Deseja cancelar por completo esta entrega?</h3>
				<v-btn class="buttons" @click="cancelDelivery" color="red"
					>Sim, cancelar tudo</v-btn
				>
				<v-btn class="buttons" @click="cancelDeliveryModal = false">Não</v-btn>
			</div>
		</v-dialog>
		<v-dialog v-model="confirmDeliveryModal">
			<div class="modal-white">
				<h3>Deseja confirmar a entrega?</h3>
				<v-btn class="buttons" @click="confirmDelivery" color="primary"
					>Sim, material entregue</v-btn
				>
				<v-btn class="buttons" @click="confirmDeliveryModal = false">Não</v-btn>
			</div>
		</v-dialog>
	</div>
</template>

<script>
	import api from "../components/api.js";
	import { mapState } from "vuex";

	export default {
		components: {},
		data: function() {
			return {
				cancelDeliveryModal: false,
				confirmDeliveryModal: false,
				qtyModalShow: false,
				alreadyDeliveredModal: false,
				deliveryCardsModal: false,
				deliveries: [],
				allUndeliveredItems: [],
				undeliveredItems: [],
				selectionItem: {},
				itemsSelectionDone: false,
				selectionIndex: 0,
			};
		},
		computed: mapState(["products", "invoiceId"]),
		methods: {
			today() {
				return new Date().toJSON().slice(0, 10);
			},
			cancelDelivery() {
				this.undeliveredItems = JSON.parse(
					JSON.stringify(this.allUndeliveredItems)
				);
				this.selectionIndex = 0;
				this.deliveryCardsModal = false;
				this.itemsSelectionDone = false;
				this.cancelDeliveryModal = false;
			},
			next() {
				if (this.selectionIndex >= this.undeliveredItems.length - 1) {
					this.selectionIndex = this.undeliveredItems.length - 1;
				} else {
					this.selectionIndex += 1;
				}
			},
			prev() {
				if (this.selectionIndex <= 0) {
					this.selectionIndex = 0;
				} else {
					this.selectionIndex -= 1;
				}
			},
			goingClickHandler() {
				this.$set(
					this.undeliveredItems[this.selectionIndex],
					"loadedQty",
					this.undeliveredItems[this.selectionIndex].qty
				);
				this.next();
			},
			notGoingClickHandler() {
				this.$set(this.undeliveredItems[this.selectionIndex], "loadedQty", 0);
				this.next();
			},
			hasGoneClickHandler() {},
			finalCheck() {
				this.itemsSelectionDone = true;
				this.deliveryCardsModal = false;
			},
			async confirmDelivery() {
				let loadedItems = [];
				let alreadyDeliveredItems = [];
				let removeIndexes = [];
				this.undeliveredItems.forEach((item, index) => {
					if (item.loadedQty > 0) {
						loadedItems.push(item);
						removeIndexes.push(index);
					} else if (item.alreadyDelivered) {
						alreadyDeliveredItems.push(item);
						removeIndexes.push(index);
					}
				});
				// eslint-disable-next-line for-direction
				for (let i = removeIndexes.length - 1; i >= 0; i--) {
					this.undeliveredItems.splice(removeIndexes[i], 1);
				}

				this.confirmDeliveryModal = false;
				this.selectionIndex = 0;
				this.itemsSelectionDone = false;
				this.allUndeliveredItems = JSON.parse(
					JSON.stringify(this.undeliveredItems)
				);
				
				let delivery = {
					date: new Date().toISOString(),
					items: loadedItems
				}
				await api.saveDelivery(delivery)

				this.deliveries = await api.fetchDeliveries(10);
			},
		},
		created: async function() {
			this.deliveries = await api.fetchDeliveries(10);
			let scheduleItems = await api.fetchInProcessParts();
			this.allUndeliveredItems = scheduleItems;
			this.undeliveredItems = JSON.parse(
				JSON.stringify(this.allUndeliveredItems)
			);
			console.log(this.undeliveredItems);
		},
	};
</script>

<style scoped>
	.modal-white {
		background-color: white;
		height: 100%;
		padding: 10px;
		text-align: center;
	}

	.review-delivery {
		background-color: #f1f7dd;
	}
	.review-delivery .listing {
		margin-top: 10px;
		max-height: 50vh;
		overflow: scroll;
	}

	.modal-qty {
		background-color: #7ee9ae;
		height: 30%;
		padding: 10px;
		text-align: center;
	}

	.modal-already-delivered {
		background-color: #c0dffd;
		height: 400px;
		padding: 10px;
		text-align: center;
	}

	.buttons {
		margin: 10px;
	}
	img {
		height: 150px;
		max-width: 100%;
	}

	.qty-input {
		font-size: 21px;
		font-weight: bold;
		background-color: white;
		width: 220px;
	}

	.cancel-link {
		color: red;
	}

	.qty {
		font-size: 24px;
		font-weight: bold;
	}

	.thumb {
		max-width: 100%;
		display: grid;
		grid-template-columns: 1fr 2fr 1fr;
		grid-gap: 10px;
		align-items: center;
	}

	.thumb img {
		height: auto;
		width: 100%;
	}

	.commands {
		display: flex;
		flex-direction: column;
		width: 100%;
	}
</style>
