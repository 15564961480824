<template>
    <Report></Report>
</template>

<script>
import Report from '../components/Report.vue'

export default {
  components: {
    Report
  }
}
</script>