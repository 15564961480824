<script>
export default {
    props: ["products"],
    methods: {
        handleClick: function(index) {
            this.$emit("onClick", index);
        },
    },
};
</script>

<template>
    <div class="product-listing-modal">
        <div v-if="products.length < 1">Sem produtos</div>
        <div v-else
            class="product-listing-modal-item"
            v-on:click="handleClick(index)"
            v-for="(product, index) in products"
            v-bind:key="index"
        >
            <img v-bind:src="product.image" />
            <div class="product-listing-modal-item-infos">
                <div>{{ product.cod }}</div>
                <div>{{ product.name }}</div>
                <div>{{ product.qty }} peças</div>
            </div>
        </div>
    </div>
</template>

<style>
.product-listing-modal {
    overflow: auto;
    max-height: 150px;
}

.product-listing-modal img {
    height: 80px;
    margin-right: 5px;
}

.product-listing-modal-item {
    display: flex;
    margin-bottom: 5px;
    font-size: 12px;
}

.product-listing-modal-item-infos {
    display: block;
}
</style>
