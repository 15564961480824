<template>
  <div>
    <v-row>
      <v-col class="col-6">
        <v-text-field
          v-model="basket.estimatedDoneDate"
          label="Data Finalização"
          persistent-hint
          :hint="dateHint(basket.estimatedDoneDate)"
          prepend-icon="mdi-calendar"
          v-on:click="openDateModal()"
        ></v-text-field>
      </v-col>
      <v-col class="col-6">
        <span>Estimativa (min):</span
        ><input class="small-input" v-model="basket.estimatedExecutionTime" />
      </v-col>
    </v-row>
    <ProductCardHorizontal
      v-for="(item, index) in basket.items"
      :key="index"
      v-bind:product="item"
      class="basket-item"
    >
    </ProductCardHorizontal>
    <div slot="footer">
      <v-btn text color="error" block @click="undoBasket()"
        >Desfazer cesto</v-btn
      >
      <v-btn block color="success" @click="showBasketDoneOptionsModal = true"
        >Concluído</v-btn
      >
    </div>
    <v-dialog v-model="showBasketDoneOptionsModal">
      <div class="modal">
        <v-container>
          <v-row>
            <h2>Cesto Finalizado</h2>
          </v-row>
          <v-row>
            <span>Tempo de Execução: </span
            ><input v-model="realExecutionTime" />
          </v-row>
          <v-row>
            <v-col>
              <v-btn @click="markBasketAsDone()">Marcar Concluído</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-dialog>
    <v-menu
      v-model="dateMenu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="auto"
    >
      <v-date-picker
        v-model="selectedBasketEstimatedDoneDate"
        no-title
        @input="saveEstimatedDoneDate(value)"
      ></v-date-picker>
    </v-menu>
  </div>
</template>
<script>
import ProductCardHorizontal from "./ProductCardHorizontal.vue";

export default {
  name: "Basket",
  props: ["basket"],
  data: function() {
    return {
      realExecutionTime: 0,
      showBasketDoneOptionsModal: false,
    };
  },
  methods: {
    dateHint(date) {
      let today = new Date(new Date().toDateString());
      let targetDate = new Date(date + "T00:00");
      let days = parseInt((targetDate - today) / (24 * 60 * 60 * 1000));
      let message = null;
      switch (days) {
        case 0:
          message = "Hoje";
          break;
        case 1:
          message = "Amanhã";
          break;
        case 2:
          message = "Depois de amanhã";
          break;
        case -1:
          message = "Ontem";
          break;
        default:
          if (isNaN(days)) {
            message = null;
          } else if (days > 0) {
            message = "Daqui " + days + " dias";
          } else {
            message = Math.abs(days) + " dias atrás";
          }
          break;
      }
      return message;
    },
    openDateModal: function() {},
    markBasketAsDone: function() {},
    undoBasket: function() {},
  },
  components: {
    ProductCardHorizontal,
  },
  created: function() {},
  computed: {},
};
</script>
<style scoped>
.modal {
  background: white;
}
</style>
